<template>
  <b-tabs pills card vertical class="inc-cpt-detail-all">
    <b-tab active v-if="configTabs.products">
      <template #title>
        <i class="fa fa-qrcode" title="Productos"></i>        
      </template>      
      <b-card-text>
        <DetailProducts @load-detail="loadProducts($event)"
                        :detailEdit="detail.products"
                        :operation="operation"/>
      </b-card-text>
    </b-tab>

    <b-tab v-if="configTabs.productsCompound">
      <template #title>
        <i class="fa fa-puzzle-piece" title="Productos Compuestos"></i>        
      </template>      
      <b-card-text>
        <DetailProductsCompound @load-detail="loadProductsCompound($event)" 
                                :detailEdit="detail.productsCompound"
                                :operation="operation"/>
      </b-card-text>
    </b-tab>
  </b-tabs>  
</template>
<script>
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import DetailProducts from './detailProducts'  
  import DetailProductsCompound from './detailProductsCompound'  
  import moment from 'moment'
  import Helper from '@/handler/helper'
  import Param from '@/config/parameters'

  export default {
    props: {     
      operation: {
        type: [Object, Array],
        default: [],        
      },
      detailProducts:{
        type: [Object, Array],
        default: [],
      },      
      detailProductsCompound:{
        type: [Object, Array],
        default: [],
      },                        
    },
    components: {      
      DetailProducts,  
      DetailProductsCompound,     
    }, 
    data: () => {
      return {          
        configTabs: {
          products: false,
          productsCompound: false,
        },
        detail: {
          products: [],
          productsCompound: [],
        },      
        detailReturn: [],
      }
    },
    mounted() {
      var modulesActive = Session.getSession().auth.user.permissions.modules
      
      modulesActive.forEach(element => {
        if(element.id == Modules.PRODUCTOS) {
          this.configTabs.products = true
          this.configTabs.productsCompound = Helper.hasParametersAccess(Param.P30)
        }   
      })

      this.detail.products = this.detailProducts
      this.detail.productsCompound = this.detailProductsCompound
    },         
    methods: {      
      loadProducts(event) {
        this.detail.products = event
        this.$emit('load-detail-products', this.detail.products)            
      },
      loadProductsCompound(event) {        
        this.detail.productsCompound = event
        this.$emit('load-detail-products-compound', this.detail.productsCompound)            
      },   
    }     
  }
</script>
<style>
  .inc-cpt-detail-all .card-header {
    padding: 0px;
  }
  .inc-cpt-detail-all .nav-link {
    padding: 5px 10px;
  }
</style>