<template>
  <div>    
    <b-row :class="[{'block-product-add':crud.form.detail_register.editing}]">
      <b-col lg="4">
        <FindObject :key="'products-compound-' + crud.form.detail_register.forceRenderSelectProduct"
                    render="search"
                    type="productsCompound" 
                    @select-object="loadProductsCompound($event)" 
                    :valueID="crud.form.detail_register.productCompound_id"
                    :where="conditionProductsCompoundActive"/> 
      </b-col>
      <b-col lg="8">
        <b-row v-if="crud.form.detail_register.unit_measurement">
          
          <b-col lg="3">
            <b-form-group label="Cantidad">
              <b-input-group size="sm" :append="crud.form.detail_register.unit_measurement.reference">
                <b-form-input v-model="crud.form.detail_register.quantity" 
                              type="number" 
                              min="0" 
                              step="1" 
                              class="form-control" />        
              </b-input-group>
            </b-form-group>            
          </b-col>                   
          <b-col lg="4">          
            <FindObject :key="'deposits-locations-origin-' + crud.form.detail_register.forceRenderSelectProduct"
                        tag="Ubicación Origen"
                        render="search"
                        type="deposits-location" 
                        @select-object="loadLocations($event)" 
                        :valueID="crud.form.detail_register.location_id"
                        :where="conditionLocationActive"                         
                        :orWhere="conditionLocationActiveOR" 
                        :showAllRegister="true"/>
          </b-col>
          <b-col lg="4">          
            <FindObject :key="'deposits-locations-destination-' + crud.form.detail_register.forceRenderSelectProduct"
                        tag="Ubicación Destino"
                        render="search"
                        type="deposits-location" 
                        @select-object="loadLocationsDestination($event)" 
                        :valueID="crud.form.detail_register.location_destination_id"
                        :where="conditionLocationActive" 
                        v-if="operation.reference == 'transferencia'"
                        :showAllRegister="false"/>
          </b-col>
          <b-col lg="1" class="crud-new-orders-button-add">              
            <b-button @click="addItem()" 
                      v-b-tooltip.hover 
                      :variant="crud.form.detail_register.button.variant"
                      :title="crud.form.detail_register.button.title"
                      size="sm">
              <i :class="crud.form.detail_register.button.icon"></i>
            </b-button>                                                  
          </b-col>

        </b-row>
      </b-col>
      <b-col lg="12" v-if="crud.form.detail_register.editing" class="block-product-add-div">
        <b-icon icon="circle-fill" animation="throb" font-scale="2"></b-icon>
      </b-col>      
    </b-row>
    <b-row class="mt-3">
      <b-col md="12">                  
        <b-table class="mb-0 crud-order-table-detail"
          responsive="sm"
          head-variant="dark"
          :hover="true"
          :small="true"
          :fixed="false"
          :items="crud.form.detail"
          :fields="tableDetailNewOrder">
          
          <template v-slot:cell(code)="data">              
            {{data.item.productCompound.code}}
          </template>  

          <template v-slot:cell(product)="data">  
            {{data.item.productCompound.name}}              
          </template>   

          <template v-slot:cell(quantity)="data">                            
            {{ parseFloat(data.item.quantity).toFixed(2) }} 
            <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
          </template>   

          <template v-slot:cell(deposit)="data">                          
            <span v-if="data.item.location && data.item.location.zone && data.item.location.zone.deposit">
              {{data.item.location.zone.deposit.reference}} /
              {{data.item.location.zone.reference}} /
              {{data.item.location.reference}}
            </span>
          </template>  

          <template v-slot:cell(deposit_destination)="data">                          
            <span v-if="data.item.location_destination && data.item.location_destination.zone && data.item.location_destination.zone.deposit">
              {{data.item.location_destination.zone.deposit.reference}} /
              {{data.item.location_destination.zone.reference}} /
              {{data.item.location_destination.reference}}
            </span>
          </template>  

          <template v-slot:cell(f_action)="data">  
            <b-dropdown right 
                        text="Acción" 
                        size="sm" 
                        variant="outline-dark"
                        :disabled="crud.form.detail_register.button.editing">                                    
              <b-dropdown-item @click="editItem(data.item, data.index)">
                <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
              </b-dropdown-item>
              <b-dropdown-item @click="deleteItem(data.index)" style="color:red;">
                <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
              </b-dropdown-item>     
            </b-dropdown>                                
          </template>                    
        </b-table>                  
      </b-col>
    </b-row> 

  </div>       
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'    
  import Param from '@/config/parameters'
  import moment from 'moment'

  export default {
    props: {
      operation: {
        type: [Object, Array],
        default: [],        
      },      
      detailEdit: {
        type: [Array, Object],
        default: null,
      },
    },
    components: {
      FindObject,
    },
    data: () => {
      return {  
        tableDetailNewOrder : [],           
        crud: {
          form: {
            detail_register: {
              quantity: 0,
              quantityOld: 0,      
              productCompound_id: 0,
              productCompound: null,     
              forceRenderSelectProduct: 0,
              unit_measurement: null,
              unit_measurement_id: 0,     
              location: null,
              location_id: 0,
              location_destination: null,
              location_destination_id: 0,             
              button: {
                icon: 'fa fa-plus',
                title: 'Agreagr Item',
                variant: 'success',
                editing: false
              },
              editing: false,                 
            },  
            detail: [],           
          },               
        },      
      }
    },
    computed: {
      // CONDITIONS SELECT   
      conditionLocationActive() {        
        var conditional = []
        conditional.push({field: 'active', condition: true})
        return conditional        
      },
      conditionLocationActiveOR() {        
        var conditional = []
                        
        if(this.crud.form.detail_register.productCompound) {
          if(this.crud.form.detail_register.productCompound.depositLocationDetail) {
            this.crud.form.detail_register.productCompound.depositLocationDetail.forEach(element => {
              if(element.quantity>0) {
                conditional.push({field: 'id', condition: element.location_id})
              }              
            });
          }
        }
        if(!conditional.length) {
          if(this.operation.type && this.operation.type.type_balance == '+')
            conditional = [];
          else 
            conditional.push({field: 'id', condition: -1})                      
        }        
        
        return conditional
      },   
      conditionProductsCompoundActive() {
        return [{field: 'active', condition: true}];
      },      
    },    
    mounted() {
      this.configFieldsTable()
      this.loadItems()
    },
    methods: {
      configFieldsTable() {
        this.tableDetailNewOrder.push({key: 'code', label: 'Código', class:"align-middle"})              
        this.tableDetailNewOrder.push({key: 'product', label: 'Producto', class:"align-middle"})              
        this.tableDetailNewOrder.push({key: 'quantity', label: 'Cantidad', class:"align-middle text-center"})                
        this.tableDetailNewOrder.push({key: 'deposit', label: 'Ubicación (Origen)', class:"align-middle text-left"})                

        if(this.operation.reference == 'transferencia') {
          this.tableDetailNewOrder.push({key: 'deposit_destination', label: 'Ubicación (Destino)', class:"align-middle text-left"})  
        }

        this.tableDetailNewOrder.push({key: 'f_action', label:'Acción', class:"align-middle text-right"})
      },
      loadItems() {              
        if(this.detailEdit) {
          this.detailEdit.forEach(element => {         
            var arrItem = { 
              productCompound: element.productCompound, 
              quantity: parseFloat(element.quantity), 
              quantityOld: parseFloat(element.quantity),
              unit_measurement: element.unit_measurement,
              location: element.location,
              location_destination: element.location_destination,              
            }

            this.crud.form.detail.push(arrItem)                  
          });
          
          this.$emit('load-detail', this.crud.form.detail)
        }
      },      
      loadProductsCompound (object) {        
        if(object){
          this.crud.form.detail_register.productCompound = object
          this.crud.form.detail_register.productCompound_id = object.id     

          this.crud.form.detail_register.unit_measurement = object.unit_measurement
          this.crud.form.detail_register.unit_measurement_id = object.unit_measurement.id               
                    
          this.crud.form.detail_register.forceRenderSelectProduct = object.id                  
        } else {                    
          this.crud.form.detail_register.productCompound = null
          this.crud.form.detail_register.productCompound_id = 0                            
          
          this.crud.form.detail_register.unit_measurement = null
          this.crud.form.detail_register.unit_measurement_id = 0     
          
          this.crud.form.detail_register.quantity = 0          
          this.crud.form.detail_register.unit_measurement = null
          this.crud.form.detail_register.unit_measurement_id = 0

          this.crud.form.detail_register.location = null
          this.crud.form.detail_register.location_id = 0
          this.crud.form.detail_register.location_destination = null
          this.crud.form.detail_register.location_destination_id = 0     
          
          this.crud.form.detail_register.button.title = "Agregar Item"
          this.crud.form.detail_register.button.icon = "fa fa-plus"
          this.crud.form.detail_register.button.variant = "success"                
        }
      },    
      loadLocations (object) {
        if(object){
          this.crud.form.detail_register.location = object
          this.crud.form.detail_register.location_id = object.id
        } else {
          this.crud.form.detail_register.location = null
          this.crud.form.detail_register.location_id = 0
        }
      },
      loadLocationsDestination (object) {
        if(object){
          this.crud.form.detail_register.location_destination = object
          this.crud.form.detail_register.location_destination_id = object.id
        } else {
          this.crud.form.detail_register.location_destination = null
          this.crud.form.detail_register.location_destination_id = 0
        }
      },

      // ABM MAIN DETAILS
      addItem() {             
        // cantidad
        if (this.crud.form.detail_register.quantity.toString() == '' || parseFloat(this.crud.form.detail_register.quantity)<=0) {
          this.$awn.alert('La cantidad debe ser mayor a cero')          
          return false
        }
                 
        // producto compuesto
        if(!this.crud.form.detail_register.productCompound) {
          this.$awn.alert('No se cargó el producto')          
          return false
        }      
        
        // deposito/zona/ubicacion Origen
        if (!this.crud.form.detail_register.location) {
          this.$awn.alert('Falta seleccionar la ubicación')          
          return false
        }      
        
        // deposito/zona/ubicacion Destino
        if(this.operation.reference == 'transferencia') {
          if (!this.crud.form.detail_register.location_destination) {
            this.$awn.alert('Falta seleccionar la ubicación destino')          
            return false
          }              
        }

        // ubicación en transferencia
        if(!this.validDepositLocation()) {
          this.$awn.alert('La ubicación en depósito de salida no puede ser la misma que la ubicación de entrada') 
          return false          
        }

        // duplicado
        if(!this.validDuplicatedItem()) {
          this.$awn.alert('Item Duplicado') 
          return false          
        }

        var arrItem = { 
          productCompound: this.crud.form.detail_register.productCompound,
          quantity: parseFloat(this.crud.form.detail_register.quantity),
          quantityOld: parseFloat(this.crud.form.detail_register.quantityOld),
          unit_measurement: this.crud.form.detail_register.unit_measurement,
          location: this.crud.form.detail_register.location,
          location_destination: this.crud.form.detail_register.location_destination,
        }

        this.crud.form.detail.push(arrItem)
        this.$emit('load-detail', this.crud.form.detail)
        
        this.crud.form.detail_register.forceRenderSelectProduct = 0
        this.loadProductsCompound(null)       
        
        this.crud.form.detail_register.button.editing = false
      },
      editItem (item, index) {    
        this.crud.form.detail_register.editing = true
        this.crud.form.detail_register.quantity = item.quantity
        this.crud.form.detail_register.quantityOld = item.quantityOld  
        this.crud.form.detail_register.location = item.location
        this.crud.form.detail_register.location_id = item.location.id
                
        if(item.location_destination) {
          this.crud.form.detail_register.location_destination = item.location_destination
          this.crud.form.detail_register.location_destination_id = item.location_destination.id        
        }
        
        this.crud.form.detail_register.productCompound_id = item.productCompound.id
        this.crud.form.detail_register.forceRenderSelectProduct = item.productCompound.id    

        this.crud.form.detail_register.editing = false
        
        this.crud.form.detail_register.button.title = "Editar Item"
        this.crud.form.detail_register.button.icon = "fa fa-save"
        this.crud.form.detail_register.button.variant = "warning"
        this.crud.form.detail_register.button.editing = true

        this.crud.form.detail.splice(index, 1)
        this.$emit('load-detail', this.crud.form.detail)
      },
      deleteItem (index) {
        this.crud.form.detail.splice(index, 1)
        this.$emit('load-detail', this.crud.form.detail)
      },
      
      // VALID DUPLICATED
      validDuplicatedItem() {
        var item = this.crud.form.detail_register.productCompound
        var location_id = this.crud.form.detail_register.location_id
        var status = true
        if(this.crud.form.detail) {
          this.crud.form.detail.forEach(element => {                                                
            if(element.productCompound.id == item.id && element.location.id == location_id) {
              status = false
            }                         
          });
        }
        return status
      },    
      
      // VALID DEPOSIT / UBICACION EN TRANSFERENCIA
      validDepositLocation() {
        var status = true
        var value = this.crud.form.detail_register

        if(this.operation.reference == 'transferencia') {
          if(parseFloat(value.location_id) == parseFloat(value.location_destination_id)) {
            status = false
          }
        }
        
        return status
      },   
        
    }
  }
</script>
<style>
  .crud-order-table-detail {
    overflow: auto;
    max-height: 350px;    
    min-height: 100px;    
  }
  .block-product-add {
    background: white;
    filter: brightness(0.4);
    border-radius: 15px;
    padding-top: 10px;
    pointer-events: none;
    opacity: 0.5;
  }
  .block-product-add-div {
    position: absolute;
    text-align: center;
    margin-top: 20px;    
  }  
  .crud-new-orders-button-add {
    margin-top: 29px;
  }
  .curd-detail-product-select-unit-measurement {
    font-size: 10px !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .curd-detail-product-select-unit-measurement button {
    font-size: 10px;
    padding-left: 3px;
    padding-right: 3px;
  }
</style>